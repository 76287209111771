<template>
  <main-layout class="padded">
    <div class="pvp-container">
      <box class="placeholder">
        <icon name="pvp-swords" />
        <div class="text">{{ $t('errors.needAuth') }}</div>
        <pvp-btn variant="primary" @click="login">
          {{ $t('account.authorize') }}
        </pvp-btn>
      </box>
    </div>
  </main-layout>
</template>

<script>
import { auth } from '@src/plugins/Auth';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'LoginPage',
  components: {
    Icon,
  },
  page() {
    return {
      title: this.$t('account.authorization'),
    };
  },
  methods: {
    login() {
      const { backUrl } = this.$route.query;
      auth.login({ backUrl });
    },
  },
};
</script>

<style lang="scss" scoped>
.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 12px;
}

.icon {
  font-size: 30px;
}

.text {
  margin-top: 12px;
}

.button {
  margin-top: 12px;
}
</style>
