<template>
  <div
    class="slide-news"
    :style="{ backgroundImage: `url(${slide.urlPreview})` }"
  >
    <div class="slide-news__fade" />
    <div class="slide-news__content">
      <div class="slide-news__labels">
        <span class="slide-news__label">{{ $t('news.few') }}</span>
        <game-tag
          v-for="(game, index) in slide.games"
          :id="game"
          :key="index"
          full-name
        />
      </div>
      <div class="slide-news__main">
        <div class="slide-news__text">
          <h2 class="slide-news__title">
            {{ slide.name }}
          </h2>
          <p class="slide-news__subtitle">
            {{ slide.previewText }}
          </p>
        </div>
        <Button
          tag="button"
          size="regular"
          type="secondary"
          :text="$t('global.more')"
          @click="handleButtonClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@components/v2/ui/Button.vue';
export default {
  components: {
    Button,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    slide: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleButtonClick() {
      this.$router.push({
        name: 'news-single',
        params: { id: this.slide.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.slide-news {
  position: relative;
  background-size: cover;
  background-position: center;
}

.slide-news__fade {
  position: absolute;
  inset: 0;
  background: linear-gradient(
    95deg,
    rgba(0, 0, 0, 0.6) 0,
    rgba(0, 0, 0, 0) 90%
  );
}

.slide-news__content {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 360px;
  padding: 60px 16px 16px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 30%,
    rgba(0, 0, 0, 0.7) 100%
  );

  @include min-laptop() {
    padding: 40px 32px 24px;
    height: 400px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
  }
}

.slide-news__labels {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: auto;
  margin-bottom: 8px;
}
.slide-news__label {
  background: #0b31b8;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #f5f5f7;
}

.slide-news__main {
  display: block;

  @include min-laptop() {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.slide-news__text {
  margin-bottom: 24px;
  @include min-laptop() {
    margin-bottom: 0;
  }
}

.slide-news__title {
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 8px;
}

.slide-news__subtitle {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}
</style>
