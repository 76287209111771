<template>
  <div class="slide-video">
    <div class="slide-video__image">
      <img :src="slide.imgPreview" :alt="slide.name" />
    </div>
    <div class="slide-video__main">
      <div class="slide-video__content">
        <div class="slide-video__text">
          <div class="slide-video__tag">
            <game-tag :id="slide.idGame" full-name />
          </div>
          <h2 class="slide-video__title">
            {{ slide.name }}
          </h2>
        </div>
        <div class="slide-video__button">
          <Button
            tag="a"
            size="regular"
            type="secondary"
            :text="$t('global.more')"
            :href="'/videos/' + slide.id"
            target="_blank"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@components/v2/ui/Button.vue';

export default {
  components: {
    Button,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    slide: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.slide-video {
  position: relative;
}

.slide-video__image {
  position: absolute;
  inset: 0;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.slide-video__main {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 360px;
  background: linear-gradient(
    95deg,
    rgba(0, 0, 0, 0.6) 0,
    rgba(0, 0, 0, 0) 90%
  );

  @include min-laptop() {
    height: 400px;
  }
}

.slide-video__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 60px 16px 16px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 30%,
    rgba(0, 0, 0, 0.7) 100%
  );

  @include min-laptop() {
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;
    padding: 40px 32px 24px;
  }
}

.slide-video__tag {
  margin-bottom: 8px;
}

.slide-video__title {
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 8px;
}
</style>
