<template>
  <div class="table-column">
    <div class="column-title">
      <template v-if="t">{{ $t(t) }}</template>
      <slot name="title" />
    </div>
    <template v-if="value">{{ value }}</template>
    <slot v-else />
  </div>
</template>

<script>
export default {
  name: 'CellSimple',
  props: {
    t: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
  },
};
</script>
