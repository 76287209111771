<template>
  <div class="table-column" :class="{ centered }">
    <template v-if="t">{{ $t(t) }}</template>
    <slot v-else />
  </div>
</template>

<script>
export default {
  name: 'CellHeader',
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    t: {
      type: String,
      default: '',
    },
  },
};
</script>
