<style lang="scss" scoped>
.placeholder {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $dark-background;
  border-radius: 3px;
  box-shadow: $default-box-shadow;
  padding-top: 30px;
  padding-bottom: 30px;

  .placeholder-icon {
    font-size: 30px;
  }

  .placeholder-text {
    text-align: center;
    margin-top: 12px;
  }

  .placeholder-action {
    margin-top: 12px;
  }

  @include max-tablet() {
    width: auto;
  }
}
</style>

<template>
  <main-layout class="padded">
    <div class="pvp-container">
      <div class="placeholder">
        <div class="placeholder-icon">
          <icon name="pvp-swords"></icon>
        </div>
        <div class="placeholder-text">
          {{ $t('errors.notFound') }}
        </div>
        <div class="placeholder-action">
          <pvp-btn :to="{ name: 'index' }" variant="primary">{{
            $t('global.toMain')
          }}</pvp-btn>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'NotFoundPage',
  components: {
    Icon,
  },
  page: () => ({
    title: 'Страница не найдена',
  }),
};
</script>
