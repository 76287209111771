<template>
  <loader :size="64" />
</template>

<script>
export default {
  name: 'LoadingPage',
  page: () => ({
    title: 'Загрузка',
  }),
};
</script>

<style lang="scss" scoped>
.loader {
  position: fixed;
  inset: 0;
  align-items: center;
}
</style>
