<template>
  <div class="slide-tournament">
    <div class="slide-tournament__fade" />
    <div class="slide-tournament__main">
      <img
        class="slide-tournament__image"
        :src="tournament.bgImage"
        alt="slide image"
      />
      <div
        v-if="tournament.countDown.ts"
        class="slide-tournament__countdown"
      >
        <CountDownFlip :deadline="tournament.countDown.ts">
          <p class="slide-tournament__countdown-title">
            {{ tournament.countDown.text }}:
          </p>
        </CountDownFlip>
      </div>
      <div class="slide-tournament__content">
        <div>
          <div class="slide-tournament__tags">
            <p class="slide-tournament__single">
              {{ $t('tournaments.single') }}
            </p>
            <game-tag :code="tournament.gameCode" :full-name="true" />
          </div>
          <div class="slide-tournament__text">
            <h3 class="slide-tournament__title">
              {{ tournament.name }}
            </h3>
            <p
              v-if="tournament.prizes.length"
              class="slide-tournament__prize"
            >
              <span class="slide-tournament__gift-title"
                >{{ $t('tournaments.prizePool') }}:</span
              >
              <pvp-tooltip
                v-for="(prize, key) in tournament.prizes"
                :key="key"
                class="box"
              >
                <div class="slide-tournament__gift">
                  <span>{{ prize.count }}</span>
                  <Icon :name="prize.icon" :inline="false" />
                </div>
                <template slot="tooltip">{{
                  prize.tooltip
                }}</template>
              </pvp-tooltip>
            </p>
          </div>
        </div>
        <div class="slide-tournament__button">
          <pvp-btn
            v-if="tournament.userInfo.canRegister"
            class="slide-tournament-button"
            variant="secondary"
            @click="startRegistration(id)"
          >
            {{ $t('global.register') }}
          </pvp-btn>
          <pvp-btn
            v-else
            :to="tournament.link"
            class="slide-tournament-button"
            variant="secondary"
          >
            {{ $t('global.more') }}
          </pvp-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountDownFlip from '@components/BaseComponents/CountdownFlip/CountdownFlip.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  components: {
    CountDownFlip,
    Icon,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    ...mapGetters('tournaments', ['getTournamentSlide']),
    tournament() {
      return this.getTournamentSlide(this.id);
    },
  },
  methods: {
    ...mapActions('tournamentRegistration', ['startRegistration']),
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.slide-tournament {
  position: relative;
}

.slide-tournament__fade {
  position: absolute;
  inset: 0;
  background: linear-gradient(
    95deg,
    rgba(0, 0, 0, 0.6) 0,
    rgba(0, 0, 0, 0) 90%
  );
}

.slide-tournament__main {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 360px;

  @include min-laptop() {
    height: 400px;
  }
}

.slide-tournament__image {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  z-index: -1;
  display: block;
  object-fit: cover;
}

.slide-tournament__countdown {
  display: none;

  @include min-laptop() {
    display: flex;
    justify-content: flex-start;
    position: relative;
    z-index: 0;
    padding: 84px 32px 32px;
  }
}

.slide-tournament__countdown-title,
::v-deep .flip-clock-text {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
}

::v-deep .flip-clock-text {
  margin-top: 2px;
}

.slide-tournament__countdown-title {
  margin-bottom: 12px;
}

.slide-tournament__content {
  color: white;
  margin-top: auto;
  position: relative;
  z-index: 0;
  padding: 60px 16px 16px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 30%,
    rgba(0, 0, 0, 0.7) 100%
  );

  @include min-laptop() {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 40px 32px 24px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
  }
}

.slide-tournament__tags {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;

  @include min-laptop() {
    justify-content: flex-start;
  }
}

.slide-tournament__single {
  background: #0b31b8;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #f5f5f7;
  margin-right: 16px;
}

.slide-tournament__title {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  @include min-laptop() {
    font-size: 24px;
    line-height: 26px;
    text-align: left;
  }

  &:not(:last-child) {
    margin-bottom: 12px;
    @include min-laptop() {
      margin-bottom: 32px;
    }
  }
}

.slide-tournament__prize {
  text-align: center;
  @include min-laptop() {
    text-align: left;
  }
}

.slide-tournament__gift-title {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #d8d8d8;
  margin-right: 4px;

  @include min-laptop() {
    display: inline;
    font-weight: 600;
  }
}

.slide-tournament__gift {
  display: flex;
  align-items: center;
  color: #16a7ff;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  @include min-laptop() {
    display: inline-flex;
  }

  span {
    margin-right: 3px;
  }

  svg {
    width: 14px;
    height: 14px;
  }
}

.slide-tournament__button {
  padding-top: 22px;
  text-align: center;
  @include min-laptop() {
    padding-top: 0;
    text-align: left;
  }

  button,
  a {
    min-width: 250px;
    @include min-laptop() {
      min-width: unset;
    }
  }
}
</style>
